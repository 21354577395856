import styled from 'styled-components'
import {device} from '../../../../theme'

export const SectionWrap = styled.div `
    width: 100%;
    height: auto;
    background-image: url(${props => props.src});
    padding-top: 80px;
    padding-bottom: 80px;
    background-position: top 35% right -68px;
    background-repeat: no-repeat;
    background-size: auto;
    background-color: #1d31a7;
    @media ${device.medium}{
        padding-top: '60px';
        padding-bottom: '60px';
    }
    @media ${device.small}{
        padding-top: '40px';
        padding-bottom: '40px';
    }
`;