import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import { MdComment, MdInfoOutline } from "react-icons/md";
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Heading from '../../../../components/ui/heading'
import Button from '../../../../components/ui/button'
import { SectionWrap } from './cta-info-area.style'

const CTASection = ({
    sectionStyle,
    heading,
    text,
    ButtonOne,
    ButtonTwo,
    ...props
}) => {
    const ctaData = useStaticQuery(graphql `
        query CtaImgFourQuery {
            skills(id: {eq: "cta-section"}) {
                bg_image
              }
        }
    `);
    const imageData = ctaData.skills.bg_image;
    return ( 
        // <Section {...sectionStyle} bgImage={imageData}>
        <SectionWrap src={imageData}>
            <Container>
                <Row className="align-items-center text-lg-left text-center">
                    <Col xl={8} lg={7}>
                        <Heading {...heading}>Assess your business potentials and find opportunities <span>for bigger success </span> </Heading> 
                    </Col> 
                    <Col xl ={4} lg={5} className="text-center">
                        <Button 
                            to="#section-contact" 
                            {...ButtonOne} 
                            icon={<MdComment/>}
                            iconposition="left"
                            icondistance="4px"
                            iconsize="16px">
                            Let 's talk 
                        </Button> 
                        <Button 
                            to="/" 
                            {...ButtonTwo} 
                            icon={<MdInfoOutline/>}
                            iconposition="left"
                            icondistance="4px"
                            iconsize="16px">
                            Get Info 
                        </Button> 
                    </Col> 
                </Row> 
            </Container> 
        {/* </Section> */}
        </SectionWrap>
    )
}

CTASection.propTypes = {
    heading: PropTypes.object
}

CTASection.defaultProps = {
    sectionStyle: {
        bgColor: '#1d31a7',
        bgposition: 'top 35% right -68px',
        bgsize: 'auto',
        pt: '80px',
        pb: '80px',
        responsive: {
            medium: {
                pt: '60px',
                pb: '60px'
            },
            small: {
                pt: '40px',
                pb: '40px'
            }
        }
    },
    heading: {
        as: 'h3',
        color: '#fff',
        child: {
            color: 'secondary'
        },
        responsive: {
            medium: {
                mb: '10px'
            }
        }
    },
    ButtonOne: {
        skin: 'light',
        m: '7px',
        hover: {
            bgColor: 'secondary',
            color: '#fff',
        }
    },
    ButtonTwo: {
        skin: 'secondary',
        m: '7px'
    }
}


export default CTASection