import styled from 'styled-components'
import {device} from '../../theme'
// import BackgroundImage from 'gatsby-background-image'

export const BannerArea = styled.div`
    padding-top: 195px;
    padding-bottom: 204px;
    width: 100%;
    height: auto;
    background-image: url(${props => props.src});
    background-size:cover;
    background-position:center;
    @media ${device.large}{
        padding-top: 120px;
        padding-bottom: 120px;
    }
    @media ${device.medium}{
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 60px;
    }
`; 

export const BannerTextWrap = styled.div `
    text-align: center;
`;

export const IntroArea = styled.section `
    padding-top: 100px;
    padding-bottom: 94px;
    @media ${device.medium}{
        padding-top: 72px;
        padding-bottom: 50px;
    }
`;

export const FaqArea = styled.div `
    padding-bottom: 70px;
`;

export const VideoBoxWrap = styled.div `
    position: relative;
    text-align: center;
    @media ${device.medium}{
        margin-top: 30px;
    }
    img{
        border-radius: 5px;
    }
`;

export const VideoBtnWrap = styled.div `
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const FeatureArea = styled.section `
    padding-bottom: 68px;
    @media ${device.medium}{
        padding-bottom: 55px;
    }
`;